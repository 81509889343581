import React from "react"
import { Row, Col } from "react-bootstrap"
import { Carousel } from "antd"
import { splitArray } from "../../utils/fp"
import Card from "../../components/card"
import styled from "styled-components"
import style from "../../assets/global-style"
import LeftIcon from "../../images/left1.png"
import BasicLayout from "../../layouts/basic-layout"

const data = [
  {
    year: 1995,
    descs: ["服务梁源小区"],
    img: "http://maps.yncce.cn/his/his01.png",
  },
  {
    year: 1997,
    descs: ["1997年8月19日", "昆明北辰物业管理有限公司正式成立"],
    img: "http://maps.yncce.cn/his/his02.png",
  },
  {
    year: 1998,
    descs: ["服务苏家塘小区"],
    img: "http://maps.yncce.cn/his/his03.png",
  },
  {
    year: 2000,
    descs: ["服务北辰小区", "服务白塔花园小区"],
    img: "http://maps.yncce.cn/his/his04.png",
  },
  {
    year: 2003,
    descs: ["服务湖畔之梦小区", "服务写字楼城建大厦"],
    img: "http://maps.yncce.cn/his/his05.png",
  },
  {
    year: 2006,
    descs: ["服务云南印象小区", "服务写字楼环球金融"],
    img: "http://maps.yncce.cn/his/his06.png",
  },
  {
    year: 2006,
    descs: ["2006年2月23日", "更名为昆明城建物业管理有限公司"],
    img: "http://maps.yncce.cn/his/his07.png",
  },
  {
    year: 2007,
    descs: ["服务商业综合体财富中心"],
    img: "http://maps.yncce.cn/his/his08.png",
  },
  {
    year: 2008,
    descs: ["服务大理论坛", "服务澄江湖畔圣水小区"],
    img: "http://maps.yncce.cn/his/his09.png",
  },
  {
    year: 2009,
    descs: ["服务抚仙湖悦椿酒店"],
    img: "http://maps.yncce.cn/his/his10.png",
  },
  {
    year: 2010,
    descs: ["服务罗平布依风情园 "],
    img: "http://maps.yncce.cn/his/his11.png",
  },
  {
    year: 2011,
    descs: ["2011年2月24日", "更名为昆明城建物业服务有限公司"],
    img: "http://maps.yncce.cn/his/his12.png",
  },
  {
    year: 2013,
    descs: ["服务罗平云之梦酒店"],
    img: "http://maps.yncce.cn/his/his13.png",
  },
  {
    year: 2014,
    descs: ["服务大理苍山小院"],
    img: "http://maps.yncce.cn/his/his14.png",
  },
  {
    year: 2015,
    descs: ["服务丽江柳溪小院"],
    img: "http://maps.yncce.cn/his/his15.png",
  },
  {
    year: 2015,
    descs: ["2015年6月24日", "更名为云南城建物业服务有限公司"],
    img: "http://maps.yncce.cn/his/his16.png",
  },
  {
    year: 2015,
    descs: ["2015年9月7日", "更名为云南城建物业集团有限公司"],
    img: "http://maps.yncce.cn/his/his17.png",
  },
  {
    year: 2016,
    descs: ["服务领秀星辰园"],
    img: "http://maps.yncce.cn/his/his18.png",
  },
  {
    year: 2017,
    descs: ["服务城市公园广场", "服务金碧阳光、香格里拉云之梦酒店"],
    img: "http://maps.yncce.cn/his/his19.png",
  },
  {
    year: 2019,
    descs: ["服务北科建春城大观"],
    img: "http://maps.yncce.cn/his/his20.png",
  },
]

const Box = styled.div`
  position: relative;
  width: 100%;
  margin-top: 38px;

  h2 {
    text-align: center;
    margin-bottom: 54px;
    color: ${style["theme-color"]};
    letter-spacing: 8px;
    font-size: 37.5px;
  }

  .turn {
    position: absolute;
    width: 34px;
    height: 34px;
    top: 22.6%;
    transform: translateY(-50%);
    background: url(${LeftIcon}) center no-repeat;
    background-size: cover;
    cursor: pointer;
  }

  .turn.left {
    left: -50px;
  }

  .turn.right {
    right: -50px;
    transform: translateY(-50%) rotate(180deg);
  }

  @media screen and (max-width: 768px) {
    .history-bar {
      display: none;
    }
  }

  .history-bar {
    width: 100%;
    margin-bottom: 38px;
    height: 20px;
    border-top: 2px solid #bbb;
    position: relative;
    margin-top: 10px;

    span {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #ccc;
      position: absolute;
      top: -9px;
      z-index: 10;
      background-color: white;
    }

    & > span:nth-child(1) {
      left: 4.5%;
    }

    & > span:nth-child(2) {
      left: 29.5%;
    }

    & > span:nth-child(3) {
      left: 54.5%;
    }

    & > span:nth-child(4) {
      left: 79.5%;
    }
  }
`

const History = () => {
  const carRef = React.useRef(null)

  return (
    <BasicLayout
      title="发展历程"
      bannerImg="http://cdn.jerryshi.com/gtkj/20200103152146.jpg"
    >
      <Box>
        <h2>云南城建物业集团有限公司发展历程</h2>
        <div
          role="button"
          tabIndex={0}
          className="turn left"
          onClick={() => carRef.current.prev()}
          onKeyDown={() => {}}
        ></div>
        <div
          role="button"
          tabIndex={0}
          className="turn right"
          onClick={() => carRef.current.next()}
          onKeyDown={() => {}}
        ></div>

        <Carousel dots={false} ref={carRef}>
          {splitArray(data).map((items, idx) => (
            <div key={idx}>
              <Row>
                <div className="history-bar">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                {items.map(item => (
                  <Col sx={12} md={3}>
                    <Card {...item} />
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Carousel>
      </Box>
    </BasicLayout>
  )
}

export default History
