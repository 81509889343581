import React from "react"
import styled from "styled-components"
import style from '../assets/global-style'

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 20rem;
  color: ${style["theme-color"]}

  img {
    width: 300px;
    height: 150px;
  }

  .year {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }

  section {
    height: 5rem;

    p {
      margin-bottom: 3px;
      font-size: 15px;
    }
  }
`

const Card = ({ year, descs, img }) => (
  <Container>
    <p className="year">{year}</p>
    <section>
      {descs.map(desc => (
        <p>{desc}</p>
      ))}
    </section>
    <img src={img} alt={year} />
  </Container>
)

export default Card
